<template>
  <div>
    <div class="text-uppercase">
      {{ $t('currentPrice') }}
      <span :class="indicatorStyle()" v-text="indicatorText()"></span>
    </div>
    <div class="cypher" :class="{ 'cypher--tiny': tiny }">
      {{ this.originalValue > 0 ? formatAmount(this.currency, this.originalValue) : "-"}}
    </div>
  </div>
</template>

<style lang="sass" scoped>
.indicator-green
  color: rgba(#090, 0.8)
.indicator-red
  color: rgba(#930, 0.8)
</style>

<script>
import helpers from '@/mixins/helpers.js';

export default {
  mixins: [helpers],
  props: {
    currency: { type: String, default: 'jpy' },
    currentValue: { type: Number, default: 0 },
    originalValue: { type: Number, default: 0 },
    tiny: Boolean,
  },
  data: () => ({
    // currency: '$',
    // currentValue: 120,
    // originalValue: 100
  }),
  methods: {
    indicatorStyle() {
      return `font-weight-black ${
        this.valueChange > 0 ? 'indicator-green' : 'indicator-red'
      }`;
    },
    indicatorText() {
      const valueChange = this.currentValue - this.originalValue;
      const valueChangePct = `${Math.floor(
        Math.abs((this.currentValue * 100) / this.originalValue - 100)
      )}%`;
      return valueChange === 0
        ? ''
        : `${valueChange > 0 ? '+' : ''}${valueChangePct}`;
    },
  },
};
</script>
