<template>
  <fragment>
    <div class="heading-size-4 font-weight-black mb-2">
      {{ $t("collectors") }}
    </div>
    <ul class="collectors">
      <li v-for="collector in collectors" :key="collector.id">
        <!-- <tippy arrow arrow-type="round" theme="dark"> -->
          <!-- <template v-slot:trigger> -->
            <router-link
              :to="{ name: 'collector', params: { id: collector.id } }"
            >
              <NeoAvatar
                size="50"
                v-if="collector.url"
                :photoURL="collector.url"
                :border-color="colorsmock[Math.round(collector.ownership * 5)]"
            /></router-link>
          <!-- </template> -->
          <!-- {{ collector.ownership }} -->
        <!-- </tippy> -->
      </li>
    </ul>
    <!-- this is for show more collectors in a modal when there are more than ¿8?-->
    <v-btn v-if="collectors.length > 8" class="mt-2" text>
      <v-icon color="base" left>mdi-plus</v-icon> See more
    </v-btn>
  </fragment>
</template>

<script>
const defaultAvatar = require("@/assets/neo-spinner.svg");
import NeoAvatar from "@/components/Molecules/NeoAvatar";
export default {
  name: "Collectors",
  props: {
    collectors: Array,
  },
  components: {
    NeoAvatar,
  },
  data: function () {
    return {
      avatar: defaultAvatar,
      colorsmock: ["#8f2d56", "#d81159", "#006ba6", "#0496ff", "#ffbc42"],
    };
  },
};
</script>
<style lang="scss" scoped>
.collectors {
  display: flex;
  flex-wrap: wrap;
}
</style>
