<template>
  <div class="work-prices pa-6">
    <PriceCurrent
      :currency="info.currency"
      :originalValue="info.originalValue"
      :currentValue="info.currentValue"
    />
    <PriceOriginal
      :currency="info.currency"
      :originalValue="info.originalValue"
    />
    <TotalUnits :value="info.availableFrames" :total="info.totalFrames" />
    <PriceUnitCurrent
      :currency="info.currency"
      :valueOriginal="info.previousFrameValue"
      :valueCurrent="info.currentFrameValue"
    />
    <div class="work-prices__cta">
      <v-btn
        :disabled="info.availableFrames === 0"
        class="mobile-fixed-bottom"
        :to="{ name: 'work.buy', params: { id: id, lang: $store.state.lang } }"
        color="primary"
        x-large
      >
        {{ $t("buy") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import PriceOriginal from "@/components/Molecules/PriceOriginal";
import PriceCurrent from "@/components/Molecules/PriceCurrent";
import PriceUnitCurrent from "@/components/Molecules/PriceUnitCurrent";
import TotalUnits from "@/components/Molecules/TotalUnits";

export default {
  name: "WorkPrices",
  components: {
    PriceOriginal,
    PriceCurrent,
    PriceUnitCurrent,
    TotalUnits,
  },
  props: {
    id: String,
    data: Object,
    info: {
      type: Object,
      default: function () {
        return {
          originalValue: 0,
          currentFrameValue: 0,
          previousFrameValue: 0,
          currency: "jpy",
        };
      },
    },
  },
};
</script>

<style lang="scss">
.work-prices {
  background: var(--color-grey);
  border-radius: 8px;
  * + * {
    margin-bottom: 24px;
  }
  &__cta {
    display: flex;
    margin-bottom: 0;
  }
}
.mobile-fixed-bottom {
  width: 80%;
  @media only screen and (max-width: 767px) {
    position: fixed;
    z-index: 2;
    bottom: 24px;
    left: 10%;
  }
  @media only screen and (min-width: 768px) {
    margin: 0 auto;
  }
}
</style>
