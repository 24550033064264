<template>
  <div>
    <div class="text-uppercase">
      {{ $t('originalPrice') }}
    </div>
    <div class="cypher" :class="{ 'cypher--tiny': tiny }">
      {{ this.originalValue > 0 ? formatAmount(this.currency, this.originalValue) : "-"}}
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers.js';

export default {
  mixins: [helpers],
  props: {
    currency: { type: String, default: 'jpy' },
    originalValue: {
      type: Number,
      default: 0,
    },
    tiny: Boolean,
  },
  methods: {
    formattedPrice() {
      return (
        `${this.currency}${Math.floor((this.originalValue / 10000)).toLocaleString() +
          '万'}` || '—'
      );
    },
  },
};
</script>
