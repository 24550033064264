<template>
  <div>
    <div class="text-uppercase">
      {{ $t('availableUnits') }}
    </div>
    <div>
      <div class="cypher" :class="{ 'cypher--tiny': tiny }">
        {{ value.toLocaleString() }}
        <div>{{ `/${total.toLocaleString()}` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
    tiny: Boolean,
  },
};
</script>
