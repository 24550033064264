<template>
  <div>
    <NeoModal
      v-if="readMore"
      eager
      :max-width="700"
      :title-header="$t('provenance')"
      @close="readMore = false"
      @clickOutsideCallback="readMore = false"
    >
      <div class="body-1 pa-5" style="white-space: pre-line">
       {{parseText}}
    </div></NeoModal>
    <div class="heading-size-4 font-weight-black mb-2">
      {{ $t("provenance") }}
    </div>
    <div>
      <div
        v-if="parseText.length > 200"
        class="body-1 mt-2"
        style="white-space: pre-line"
        v-text="`${parseText.substring(0, 200)} [...]`"
      />
      <div
        v-else
        class="body-1 mt-2"
        style="white-space: pre-line"
        v-text="parseText"
      />
      <div class="d-flex justify-end">
        <v-btn
          @click="showMore"
          color="primary mt-1"
          v-if="parseText.length > 200"
          small
        >
          {{ $t("seeMore") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NeoModal from "@/components/Molecules/NeoModal";

export default {
  name: "Provenance",
  components: { NeoModal },
  props: {
    data: {
      type: Object,
      default() {
        return { en: '""', es: '""', ja: '""' };
      },
    },
  },
  data() {
    return {
      readMore: false,
    };
  },
  methods: {
    showMore() {
      this.readMore = !this.readMore;
    },
  },
  computed: {
    parseText() {
      return JSON.parse(this.data[this.$store.state.lang]);
    },
  },
};
</script>
