import { db } from "@/helpers/firebase";

const COLLECTION = "Artists";
const collectionRef = db.collection(COLLECTION);
// import localforage from "localforage";

class ArtistDS {
  async read(_id) {
    // const dataPromise = new Promise((resolve, reject) => {
    //   localforage.getItem(_id).then(async (cachedData) => {
    //     const ts = await localforage.getItem(`${_id}TS`) || 0;
    //     // const age = (Math.floor((Date.now()/1000) - ts))/86400; // age in days
    //     const age = (Math.floor((Date.now()/1000) - ts))/60; // age in minutes
    //     if (cachedData && (age < 720 || navigator.Online)) resolve(cachedData);
    //     else {
    //       try {
    //         const snapshot = await collectionRef.doc(_id).get();
    //         const data = snapshot.data();
    //         localforage.setItem(_id, data);
    //         localforage.setItem(`${_id}TS`, Math.floor(Date.now() / 1000));
    //         resolve(data);
    //       } catch {
    //         reject();
    //       }
    //     }
    //   });
    // });
    // return dataPromise;
    const snapshot = await collectionRef.doc(_id).get();
    const data = snapshot.data();
    return data;
  }

  // read(_id = null) {
  //   this.artists = [];
  //   if (_id === null) {
  //     // If no id provided, return all
  //     return collectionRef.get();
  //   } else {
  //     return collectionRef.doc(_id).get();
  //   }
  // }
}

export default new ArtistDS();
