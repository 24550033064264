<template>
  <div>
    <div class="text-uppercase">
      {{ $t('unitPrice') }}
    </div>
    <div>
    <div v-if="currency" class="cypher" :class="{ 'cypher--tiny': tiny }">
      {{ formatAmount(this.currency, this.valueCurrent) }}
      <div>+{{$t('tax')}}</div>
    </div>
    
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers.js';

export default {
  name: 'PriceUnitCurrent',
  mixins: [helpers],
  props: {
    currency: String,
    valueCurrent: { type: Number, default: 0 },
    valueOriginal: { type: Number, default: 0 },
    tiny: Boolean,
    tax: { type: Number, default: 0.1 },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.tax-tag{
  font-size: var(--font-size-5);
}
</style>
